import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import EditTextComponent from "../../components/editText/EditTextComponent";
import EditHTMLComponent from "../../components/editHTML/EditHTMLComponent";
import EditImageComponent from "../../components/editImage/EditImageComponent";
import {MAX_MOBILE_WIDTH, UseWindowSize} from "../../helper/util";
import {loadTextsByNamespace} from "../../../server/helper/ssrHelper";
import config from "../../config/main.config";
import Card from "semantic-ui-react/dist/commonjs/views/Card";
import {useLocation} from "react-router-dom";
import {Icon} from "semantic-ui-react";
import history from "../../helper/browserHistory";
import i18n from "../../i18n";

const StationaerPage = () => {
    let applicationWidth = UseWindowSize().width;
    let location = useLocation();

    const [showMoreText, setShowMoreText] = useState([false, false, false]);

    useEffect(() => {

        setTimeout(_ => {
            if (location.search && typeof window !== "undefined" && typeof document !== "undefined") {
                let element = document.getElementById(location.search.substring(1, location.search.length));
                if (element) {
                    element.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center'
                    })
                }
            }
        }, 20)

    }, [location.search]);

    const namespace = 'stationaer';

    return (
        <div className={'grid ui'}>
            <div style={{display: 'none'}}>
                <BreadCrumbs breadCrumbData={[{title: 'Stationär-ÜBerUns', url: '/stationär/uberuns'}]}/>
            </div>
            <div className={"top-image"} id={'stationaer-top'}
                 style={{
                     backgroundImage: "url(" + config.BASE_URL_IMAGES + "stationaer_imagebild.webp)",
                     backgroundPositionY: 'top'
                 }}/>
            <div className={'row centered'} style={{marginBottom: '200px'}}>
                <div className={'fourteen wide column page-content-wrapper'}
                     style={applicationWidth >= MAX_MOBILE_WIDTH ? {
                         position: 'absolute',
                         bottom: '-150px',
                         padding: 0
                     } : {}}>
                    {applicationWidth >= MAX_MOBILE_WIDTH ?
                        <Card className={'card-position'} style={{float: 'left', minWidth: '50%'}}>
                            <Card.Header className={'card-header'}>
                                <h1 style={{paddingBottom: "45px"}}><EditTextComponent id={`${namespace}-card-1-header`}
                                                                                       namespace={namespace}/></h1>
                            </Card.Header>
                            <Card.Content style={{borderTop: '0', padding: '1rem 0 0 0'}}>
                                <p>
                                    <EditTextComponent id={`${namespace}-card-1-text`}
                                                       namespace={namespace}/>
                                </p>
                            </Card.Content>
                        </Card> :
                        [<h1><EditTextComponent id={`${namespace}-card-1-header`} namespace={namespace}/>
                        </h1>,
                            <p>
                                <EditTextComponent id={`${namespace}-card-1-text`}
                                                   namespace={namespace}/>
                            </p>]
                    }
                </div>
            </div>
            <div className={'row centered page-content-wrapper'}>
                <div className={'sixteen wide column'} style={{marginBottom:'3rem'}}>
                    <div className={'svg-header-container'}>
                        <svg viewBox="0 0 100 100">
                            <line x1="0" y1="0" x2="100" y2="0" stroke="#1272a1" strokeWidth="1"/>
                        </svg>
                    </div>
                    <h1 id={'stationaer-pflege'} className={'svg-header-position'} style={{
                        textAlign: 'center',
                        marginTop: '4rem',
                        marginBottom: '4rem',
                        margin: 'auto',
                        width: '800px',
                        background: 'white'
                    }}>
                        <EditTextComponent id={`${namespace}-header-2`}
                                           namespace={namespace}/>
                    </h1>
                </div>
            </div>
            <div className={'row centered page-content-wrapper'} style={{paddingBottom: '100px', columnCount: 3}}>
                <div className={'five wide computer fourteen wide mobile column padding-left-right-bow-section'}>
                    <div className={'image-container-small-text'}>
                        <EditImageComponent id={`${namespace}-image-2`}/>
                    </div>
                    <h2 className={'padding-left-right-stationaer'}><EditTextComponent id={`${namespace}-subheader-1`}
                                                                                       namespace={namespace}/></h2>
                    <p className={showMoreText[0] ? 'padding-left-right-stationaer' : 'padding-left-right-stationaer text-ellipsis'}>
                        <EditTextComponent id={`${namespace}-text-2`}
                                           namespace={namespace}/>
                    </p>
                    <button className={'button ui circular see-more-button'} onClick={_ => {
                        let newValue = [...showMoreText];
                        newValue[0] = !showMoreText[0];
                        setShowMoreText(newValue);
                    }}><Icon inverted size={'large'} name={showMoreText[0] ? 'minus' : 'plus'}
                             style={{margin: 'auto'}}/></button>
                </div>
                <div className={'five wide computer fourteen wide mobile column padding-left-right-bow-section'}>
                    <div className={'image-container-small-text'}>
                        <EditImageComponent className id={`${namespace}-image-3`}/>
                    </div>
                    <h2 className={'padding-left-right-stationaer'}><EditTextComponent id={`${namespace}-subheader-2`}
                                                                                       namespace={namespace}/></h2>
                    <p className={showMoreText[1] ? 'padding-left-right-stationaer' : 'padding-left-right-stationaer text-ellipsis'}>
                        <EditTextComponent id={`${namespace}-text-3`}
                                           namespace={namespace}/>
                    </p>
                    <button className={'button ui circular see-more-button'} onClick={_ => {
                        let newValue = [...showMoreText];
                        newValue[1] = !showMoreText[1];
                        setShowMoreText(newValue);
                    }}><Icon inverted size={'large'} name={showMoreText[1] ? 'minus' : 'plus'}
                             style={{margin: 'auto'}}/></button>
                </div>
                <div className={'five wide computer fourteen wide mobile column padding-left-right-bow-section'}>
                    <div className={'image-container-small-text'}>
                        <EditImageComponent id={`${namespace}-image-4`}/>
                    </div>
                    <h2 className={'padding-left-right-stationaer'}><EditTextComponent id={`${namespace}-subheader-3`}
                                                                                       namespace={namespace}/></h2>
                    <p className={showMoreText[2] ? 'padding-left-right-stationaer' : 'padding-left-right-stationaer text-ellipsis'}>
                        <EditTextComponent id={`${namespace}-text-4`}
                                           namespace={namespace}/>
                    </p>
                    <button className={'button ui circular see-more-button'} onClick={_ => {
                        let newValue = [...showMoreText];
                        newValue[2] = !showMoreText[2];
                        setShowMoreText(newValue);
                    }}><Icon inverted size={'large'} name={showMoreText[2] ? 'minus' : 'plus'}
                             style={{margin: 'auto'}}/></button>
                </div>
            </div>
            <div className={'ui grid'} style={{
                padding: 0,
                position: 'relative',
                width: '101%',
                backgroundImage: 'url(' + config.BASE_URL_IMAGES + 'stationaer_blaue-Flaeche.svg)',
                backgroundSize: '102%',
                backgroundRepeat: 'no-repeat',
                background: '#1272a1',
            }}>
                <img style={{width: '101%', padding: 0}} alt={'bogen ambulant'}
                     src={config.BASE_URL_IMAGES + "bows/Bogen2_Footer_Hospiz_Weiss.svg"}/>
                <div className={'row centered page-content-wrapper'}
                     style={{paddingTop: '16rem', paddingBottom: '2rem'}}>
                    <div className={'eight wide computer fourteen wide mobile column padding-right-bow-section'}>
                        <h1 id={'stationaer-beratung'} className={'color-white'}>
                            <EditTextComponent id={`${namespace}-header-3`}
                                               namespace={namespace}/>
                        </h1>
                        <p className={'color-white'}>
                            <EditHTMLComponent id={`${namespace}-text-5`} namespace={namespace}/>
                        </p>
                        <button onClick={_ => {
                            history.push('/' + i18n.language + '/contact#stationaer')
                        }} className={'button-blue'}>TERMIN VEREINBAREN >
                        </button>
                    </div>
                    <div className={'eight wide computer fourteen wide mobile column padding-left-bow-section'}>
                        <h1 className={'color-white'}><EditTextComponent
                            id={`${namespace}-header-4`}
                            namespace={namespace}/></h1>
                        <p className={'color-white'}>
                            <EditHTMLComponent id={`${namespace}-text-6`} namespace={namespace}/>
                        </p>
                        <button onClick={_ => history.push('/' + i18n.language + '/donate')} className={'button-blue'}
                                style={applicationWidth >= MAX_MOBILE_WIDTH ? {
                                    position: 'absolute',
                                    bottom: '0'
                                } : {}}>SPENDEN >
                        </button>
                    </div>
                </div>
                    <div className={'row centered page-content-wrapper team-bottom-padding'} >
                        <div className={'eight wide computer fourteen wide mobile column padding-right-bow-section'}>
                            <EditImageComponent id={`${namespace}-image-5`}/>
                        </div>
                        <div className={'eight wide computer fourteen wide mobile column padding-left-bow-section'}
                             style={{margin: 'auto'}}>
                            <h1 id={'stationaer-team'} className={'color-white'}><EditTextComponent
                                id={`${namespace}-header-5`}
                                namespace={namespace}/></h1>
                            <p className={'color-white'}>
                                <EditTextComponent id={`${namespace}-quote-text-1`}
                                                   namespace={namespace}/>
                            </p>
                        </div>
                    </div>

                <img style={{
                    width: '101%', padding: 0, position: 'absolute',
                    bottom: '-2vw'
                }} alt={'bogen ambulant'}
                     src={config.BASE_URL_IMAGES + "bows/Weiß_Bogen_Footer.svg"}/>
            </div>
            <div style={{display: 'flex', width: '100%', position: 'relative', bottom: '-140px'}}>
                <svg width='1000' height='8' style={{margin: 'auto'}}>
                    <line x1='0' y1='0' x2='100%' y2='0' stroke='#1272a1' strokeWidth='8'/>
                </svg>
            </div>
            <div className={'row centered'} style={{marginTop: '6rem', marginBottom: '2rem'}}>
                <div className={'column'} style={{display: 'flex'}}>
                    <h1 id={'stationaer-raeumlichkeiten'} style={{
                        textAlign: 'center',
                        marginTop: '4rem',
                        marginBottom: '4rem',
                        margin: 'auto',
                        width: '800px',
                        background: 'white'
                    }}>Unsere Räumlichkeiten</h1>
                </div>
            </div>
            <div className={'row centered'} style={{marginBottom: '2rem'}}>
                <div className={'six wide computer fourteen wide mobile column'} style={{display: 'flex'}}>
                    <EditHTMLComponent id={`${namespace}-text-6-1`}/>
                </div>
            </div>
            <div className={'row centered page-content-wrapper'} style={{columnCount: 3}}>
                <div className={'five wide computer fourteen wide mobile column'}>
                    <div className={'image-container-small-text'}>
                        <EditImageComponent id={`${namespace}-image-6`}/>
                    </div>
                    <h2 className={'padding-left-right-stationaer'}><EditTextComponent id={`${namespace}-subheader-4`}
                                                                                       namespace={namespace}/></h2>
                    <p className={'padding-left-right-stationaer'}>
                        <EditTextComponent id={`${namespace}-text-9`} namespace={namespace}/>
                    </p>
                </div>
                <div className={'five wide computer fourteen wide mobile column'}>
                    <div className={'image-container-small-text'}>
                        <EditImageComponent id={`${namespace}-image-7`}/>
                    </div>
                    <h2 className={'padding-left-right-stationaer'}><EditTextComponent id={`${namespace}-subheader-5`}
                                                                                       namespace={namespace}/></h2>
                    <p className={'padding-left-right-stationaer'}>
                        <EditTextComponent id={`${namespace}-text-10`}
                                           namespace={namespace}/>
                    </p>
                </div>
                <div className={'five wide computer fourteen wide mobile column'}>
                    <div className={'image-container-small-text'}>
                        <EditImageComponent id={`${namespace}-image-8`}/>
                    </div>
                    <h2 className={'padding-left-right-stationaer'}><EditTextComponent id={`${namespace}-subheader-6`}
                                                                                       namespace={namespace}/></h2>
                    <p className={'padding-left-right-stationaer'}>
                        <EditTextComponent id={`${namespace}-text-11`}
                                           namespace={namespace}/>
                    </p>
                </div>
            </div>
            <div style={{display: 'flex', width: '100%', position: 'relative', bottom: '-140px'}}>
                <svg width='1000' height='8' style={{margin: 'auto'}}>
                    <line x1='0' y1='0' x2='100%' y2='0' stroke='#1272a1' strokeWidth='8'/>
                </svg>
            </div>
        </div>
    )
}

StationaerPage.fetchData = () => {
    return loadTextsByNamespace(config.BASE_URL, 'stationaer').then((text) => {
        return text;
    })
}

export default withTranslation()(StationaerPage);